import React from 'react'
import { connect } from 'react-redux'
import MobileAppPage from '../mobile-app'

function MobileAppEn({ translations, lang }) {
    return (
        <MobileAppPage translations={translations} lang={lang}/>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(MobileAppEn)