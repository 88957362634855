import React, { useCallback, useEffect, useRef, useState } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { TweenLite } from 'gsap/all'
import { animationType, buildScrollTriggers, createAnimationType, doAnimate, nl2br, resetElements } from '../utils/functions'
import { connect } from 'react-redux'
import { ScrollTrigger } from 'gsap/all'


import MobileAppScreensImage from './../images/mobileapp/mobile-app-screens.png'

import cashlessImageSmartphone from './../images/machines/cashless.png'
import cashlessImageSmartphoneLarge from './../images/machines/cashless@2x.png'
import paymentproviders from './../images/machines/payment_providers.png'
import iphone from './../images/machines/iphone.png'
import iphoneLarge from './../images/machines/iphone@2x.png'
import bg3 from './../images/machines/bg3.png'
import bg3_Large from './../images/machines/bg3@2x.png'
import { Image } from '../components/ux/image'
import Jumpbutton from '../components/ux/buttons/jumpbutton/jumpbutton'


function MobileApp({ translations, lang }) {
    const lightHolderRef = useRef();
    const swingerRef = useRef();
    const [rendered, setRendered] = useState(false)

    createAnimationType();

    // Szene 1
    const imageRef = useRef();
    const h1Ref = useRef();
    const textRef = useRef();

    // Szene 2
    const cashlessImageRef = useRef();
    const cashlessTextRef = useRef();
    const cashlessH1Ref = useRef();
    const cashlessPaymentsRef = useRef();


    // Refs für scrolling
    const scrollRef1 = useRef();
    const scrollRef2 = useRef();

    const scrollRefs = [
        scrollRef1,
        scrollRef2,
    ];

    // Aktuelle Position
    let currentScrollPos = useRef(1);
    let animationActive = useRef(false);
    let entered = useRef(false);

    // Entsprechender Scrolleffekt
    const scrollEffect = useCallback((step) => {
        var durationFactor = 1;
        var delayFactor = .5;
        animationActive.current = true;
        switch (step) {
            case 1: // Start.
                if (entered.current) {// Zurückanimation
                    TweenLite.to(swingerRef.current, { ease: animationType, xPercent: 0, rotation: 0, duration: 1.5 })

                    TweenLite.to(cashlessImageRef.current, { ease: animationType, x: -50, y: 100, autoAlpha: 0 });
                    TweenLite.to(cashlessH1Ref.current, { ease: animationType, y: 150, autoAlpha: 0 });
                    TweenLite.to(cashlessTextRef.current, { ease: animationType, y: 100, autoAlpha: 0 });
                    TweenLite.to(cashlessPaymentsRef.current, {
                        ease: animationType, y: 50, autoAlpha: 0, delay: 1, onComplete: () => {
                            resetElements([cashlessImageRef.current, cashlessH1Ref.current, cashlessTextRef.current, cashlessPaymentsRef.current]); // Nachfolger
                            animationActive.current = false
                        }
                    });

                } else { // Startanimation                    
                    TweenLite.from(swingerRef.current, { ease: animationType, x: -300, autoAlpha: 0, duration: 1 })
                    TweenLite.from(swingerRef.current, { x: 500, autoAlpha: 0, ease: animationType })
                }
                TweenLite.from(h1Ref.current, { x: -150, y: -50, autoAlpha: 0, ease: animationType });
                TweenLite.from(textRef.current, { x: -150, y: 50, autoAlpha: 0, ease: animationType });
                TweenLite.from(imageRef.current, {
                    x: -150, y: -50, autoAlpha: 0, ease: animationType, onComplete: () => {
                        animationActive.current = false
                    }
                });
                entered.current = true;
                break;
            case 2: // CASHLESS IS KING.
                // Alte Szene ausblenden
                TweenLite.to(h1Ref.current, { ease: animationType, x: -50, y: -100, autoAlpha: 0 });
                TweenLite.to(textRef.current, { ease: animationType, x: -20, y: -50, autoAlpha: 0, duration: durationFactor });
                TweenLite.to(imageRef.current, { ease: animationType, autoAlpha: 0, y: -100, duration: (durationFactor) });

                TweenLite.to(swingerRef.current, { xPercent: -100, rotation: 90, duration: 1.5 });
                TweenLite.from(cashlessImageRef.current, { ease: animationType, x: -50, y: 100, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(cashlessH1Ref.current, { ease: animationType, y: 150, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(cashlessTextRef.current, { ease: animationType, y: 100, autoAlpha: 0, delay: delayFactor });
                TweenLite.from(cashlessPaymentsRef.current, {
                    ease: animationType, y: 50, autoAlpha: 0, delay: delayFactor, onComplete: () => {
                        resetElements([h1Ref.current, textRef.current, imageRef.current]);
                        animationActive.current = false;
                    }
                });
                break;
            default:

                break;

        }
    }, []);

    const clickHandler = (e) => {
        if (!doAnimate()) return
        currentScrollPos.current += 1
        scrollEffect(currentScrollPos.current);
        scrollToRef(scrollRefs[currentScrollPos.current - 1])
    }

    // Scrollt zur nächsten Section
    const scrollToRef = (ref, down) => {
        let offset = ref.current.offsetTop + 108;
        if (currentScrollPos.current === 2) { // Bis footer
            offset += 180;
        }
        if (!down) {
            offset = ref.current.offsetTop;
        }
        TweenLite.to(window, { duration: 1.5, scrollTo: offset });

    };

    // Initialisierung
    useEffect(() => {
        if (doAnimate()) {
            document.body.classList.add('noscroll');
            TweenLite.defaultEase = animationType;

            if (rendered !== true) {
                setRendered(true);
                setTimeout(() => {
                    scrollEffect(1);

                    buildScrollTriggers(scrollRefs, scrollEffect, scrollToRef, currentScrollPos, animationActive);
                }, 500);
                setTimeout(() => {
                    document.body.classList.remove('noscroll');
                }, 2000)
            }
            return () => {
                ScrollTrigger.getAll().forEach(t => t.kill());
            }
        }
    }, [scrollEffect, scrollRefs, rendered])


    return (
        <div>
            <Layout>
                <SEO title={translations.app_title} description={translations.app_meta_description} />
                <div className="mobile-app subpage">
                    <div className="lightholder" ref={lightHolderRef}></div>
                    {/* Erste Szene */}
                    <section ref={scrollRef1} className="one">
                        <div className="swinger animationElement" ref={swingerRef}></div>
                        <div className="inner-wrapper flex">
                            <div>
                                <div ref={h1Ref} className="animationElement">
                                    <h1>
                                        {
                                            nl2br(translations.app_white_headline)
                                        }<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.app_yellow_headline)
                                            }
                                        </span>
                                    </h1>
                                    <p className="t-upper mt">
                                        {
                                            nl2br(translations.app_subline)
                                        }
                                    </p>
                                </div>
                                <div><img ref={imageRef} className="animationElement mobile mt-2 mb-2" src={MobileAppScreensImage} alt="Mobile App Screens" /></div>
                                <div ref={textRef} className="animationElement">
                                    <p className="light mb-2 mt">
                                        {
                                            nl2br(translations.app_description)
                                        }
                                    </p>
                                </div>
                            </div>
                            <div><img ref={imageRef} className="animationElement mobile-exclude" src={MobileAppScreensImage} alt="Mobile App Screens" /></div>
                        </div>
                    </section>
                    { /* Zweite Szene */}
                    <section className="second" ref={scrollRef2}>
                        <div className="inner-wrapper flex">
                            <div ref={cashlessImageRef} className="mobile-exclude animationElement">
                                <Image img={cashlessImageSmartphone} img2={cashlessImageSmartphoneLarge} alt="Mobile App" styles="hovergrow" />
                            </div>
                            <div className="flexPaddingLeft">
                                <div ref={cashlessH1Ref} className="animationElement">
                                    <div className="bs-headline">
                                        {
                                            nl2br(translations.machines_5_white_headline)
                                        }<br />
                                        <span className="yellow">
                                            {
                                                nl2br(translations.machines_5_yellow_headline)
                                            }
                                        </span>
                                    </div>
                                    <p className="t-upper mt mb bolder">
                                        {
                                            nl2br(translations.machines_5_subline)
                                        }
                                    </p>
                                </div>
                                <Image img={bg3} img2={bg3_Large} alt="background" styles="mobile_background bg3" />
                                <Image img={iphone} img2={iphoneLarge} alt="iPhone" styles="mobile scale-80" />
                                <div ref={cashlessTextRef} className="animationElement">
                                    <p className="light mb-2">
                                        {
                                            nl2br(translations.machines_5_description)
                                        }
                                    </p>
                                </div>
                                <img src={paymentproviders} alt="Paymentproviders" ref={cashlessPaymentsRef} className="hovergrow" />
                            </div>
                        </div>
                    </section>
                </div>
                <Jumpbutton clickHandler={clickHandler} />
            </Layout>
        </div>
    )
}


const mapStateToProps = state => ({
    translations: state.preferences.translations,
    lang: state.preferences.lang,
    serverCall: state.preferences.serverCall
})

export default connect(mapStateToProps)(MobileApp)